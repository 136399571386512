import { HttpInterceptorFn } from '@angular/common/http';
import {inject} from "@angular/core";
import {AuthService} from "./auth/auth.service";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authservice = inject(AuthService)
  const authToken = authservice.getToken();
  if (authToken){
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });

    return next(authReq);
  }else {
    return next(req)
  }

};
